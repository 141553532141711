export const cardsData = [
  {
    imagePath: "/static/media/game-console.webp",
    title: "Play Games",
    heading: "Games",
    description: "Free games to play online!",
    dataAos: "fade-right",
  },
  {
    imagePath: "/static/media/audio-book.webp",
    title: "Books",
    heading: "Books",
    description:
      "Free ebooks to read and download. Get Some stuff and Read the Books",
    dataAos: "fade-up",
  },
  {
    imagePath: "/static/media/audiobook.webp",
    title: "Audio Books",
    heading: "Audio Books",
    description:
      "Audio books online. Very lazy to read the books,listen the books",
    dataAos: "fade-left",
  },
  {
    imagePath: "/static/media/news.webp",
    title: "News",
    heading: "News",

    description: "Daily Updated Real News",
    dataAos: "fade-down",
  },
  {
    imagePath: "/static/media/socioclubcontest2.webp",
    title: "Contests",
    heading: "Contests",
    description: "On Everything Participate and Win Rewards",
    dataAos: "fade-right",
  },
  {
    imagePath: "/static/media/digital.webp",
    title: "NFT's",
    heading: "NFT's",
    description: "Check Trending Nft's and Crypto Things",
    dataAos: "fade-up",
  },
  {
    imagePath: "/static/media/music-note.webp",
    title: "Music",
    heading: "Music",
    description: "Listen to free Music.",
    dataAos: "fade-left",
  },
  {
    imagePath: "/static/media/radio.webp",
    title: "Radio",
    heading: "Radio",
    description: "Listen to all Radio live stations free!",
    dataAos: "fade-down",
  },
  {
    imagePath: "/static/media/chatbot.webp",
    title: "Chatbot",
    heading: "Chatbot",
    description:
      "Lucy Chat bot, which will entertain with voice and talks in chat",
    dataAos: "fade-right",
  },
  {
    imagePath: "/static/media/Meme.webp",
    title: "Memes",
    heading: "Memes",
    description: "Explore all time best memes live.",
    dataAos: "fade-up",
  },
  {
    imagePath: "/static/media/live.webp",
    title: "Podcasts",
    heading: "Podcasts",
    description: "Listen to Podcasts All over the World",
    dataAos: "fade-left",
  },
  {
    imagePath: "/static/media/anonymous.webp",
    title: "Anonymous",
    heading: "Anonymous",
    description:
      "Anyone can Securely post anything, as truth as hard, as Anonymous person.",
    dataAos: "fade-down",
  },
  {
    imagePath: "/static/media/movie.webp",
    title: "Gaming Videos",
    heading: "Gaming Videos",
    description: "Watch all the Free Gaming Videos.",
    dataAos: "fade-right",
  },
  {
    imagePath: "/static/media/fitness.webp",
    title: "Fitness",
    heading: "Fitness",
    description: "Watch free live Exercises and do Workouts and Be Fit.",
    dataAos: "fade-up",
  },
  {
    imagePath: "/static/media/quiz.webp",
    title: "Quiz",
    heading: "Quiz",
    description: "Play and Participate in All Free Quizs",
    dataAos: "fade-left",
  },
  {
    imagePath: "/static/media/lotus.webp",
    title: "Breathing",
    heading: "Breathing",
    description: "Practice Breathing and Be Healthy",
    dataAos: "fade-down",
  },
  {
    imagePath: "/static/media/shopping-cart.webp",
    title: "Shopping",
    heading: "Shopping",
    description: "Shop online everything.",
    dataAos: "fade-right",
  },
  {
    imagePath: "/static/media/cryptocurrency.webp",
    title: "Exchange Cryptocurrency",
    heading: "Exchange Cryptocurrency",
    description: "Check all live crypto prices.",
    dataAos: "fade-up",
  },
];
