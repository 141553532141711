import React, { useEffect } from "react";
import "./bids.css";
import { cardsData } from "../constants/cardData";

import Aos from "aos";
import "aos/dist/aos.css";
import Card from "../cards/Card";

const Bids = ({ title }) => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="bids section__padding" id="Features">
      <div className="bids-container">
        <div data-aos="zoom-in" className="bids-container-text">
          <p className="fw-bolder display-4 text-center">Club Features</p>
          <br />
        </div>
        <div className="bids-container-card">
          {cardsData.map((cardData) => {
            return (
              <Card
                imagePath={cardData.imagePath}
                title={cardData.title}
                heading={cardData.heading}
                description={cardData.description}
                dataAos={cardData.dataAos}
                key={cardData.title}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Bids;
